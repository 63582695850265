<template>
   <div style="overflow:initial;">
       <!-- เมนูทางขวา -->
       <v-navigation-drawer v-model="drawer" floating :color="color.theme" :dark="color.darkTheme" app><div class="text-center mt-2">
        <img src="@/assets/logo_onebox-horizontal-white.png" width="38%" height="38%" />
      </div>
      <v-list dense nav>
        <!-- <v-divider></v-divider> -->
        <!-- อัปโหลดไฟล์ -->
        <v-menu> 
          
        </v-menu> 
         <v-divider></v-divider>
         <!-- กดเปลี่ยนบริษัท -->
         <!-- <div class="pa-2">
          <v-autocomplete
            prepend-inner-icon="work"
            v-model="choosetypeuser"
            @change="changestorage_sidebar(choosetypeuser)"
            :items="typeuser"
            :item-text="$t('default') === 'en' ? 'nameen': 'nameth'"
            hide-details
            outlined
            dense
            return-object
            :item-color="color.theme"
          >
            <template :color="color.theme" v-slot:item="{ item }">
              <span v-if="$t('default') === 'en'" style="font-size: 14px; " >{{ item.nameen }}</span>
              <span v-else style="font-size: 14px; " >{{ item.nameth }}</span>
            </template>
            <template :color="color.theme" v-slot:selection="{ item }">
              <span v-if="$t('default') === 'en'" style="font-size: 13px; " >{{ item.nameen }}</span>
              <span v-else style="font-size: 13px; "  >{{ item.nameth }}</span>
            </template>
          </v-autocomplete>
        </div> -->
        <!-- <v-divider></v-divider> -->
        <!-- เปลี่ยนไปหน้าอื่นๆ -->
        <template v-for="item in listmenu()">
         <v-list-item :to="item.link" :disabled="item.disable" :key="item.text">
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
          </v-list-item>    
        </template>
      </v-list>
      <!-- <v-divider></v-divider> -->
      <!-- เปอรเซ็น storage -->
      <template v-slot:append>
        <div>
         
        </div>
      </template>
      </v-navigation-drawer>

        <!-- เมนูข้างบน -->
        <v-app-bar class="elevation-0" outlined app color="white">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
             <!-- search -->
            <!-- <v-row class="ma-5" v-if="quicksearch === true">
        <v-col cols="12" sm="6" lg="6">
          <v-text-field
            v-if="imageHeight >= 400"
            v-model="search"
            :label="$t('quicksearch')"
            append-icon="search"
            dense
            single-line
            outlined
            clearable
            hide-details
            @input="searchdata()"
            :color="color.theme"
          ></v-text-field>
        </v-col>
      </v-row> -->
            <v-spacer></v-spacer>
            <!-- อัปโหลดไฟล์ -->
            <v-menu></v-menu>
      <v-divider inset vertical></v-divider>
      <!-- โปรไฟล์ -->
      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on }">
          <v-btn text x-large v-on="on">
            <v-icon class="mr-2" large color="blue-grey darken-4">account_circle</v-icon>
            <div class="hidden-sm-and-down text-left">
              <b style="color: #263238">{{ fullname }}</b>
              <br />
              <span
                style="font-size: 13px; color: #263238"
              >{{ $store.getters.dataTypeusercurrent($store.state.account_active['type'],$t('default')) }}</span>
            </div>
          </v-btn>
        </template>
        <v-card>
          <v-list nav>
            <v-list-item>
              <v-list-item-avatar>
                <img src="https://image.flaticon.com/icons/svg/149/149071.svg" alt="John" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ fullname }}</v-list-item-title>
                <v-list-item-subtitle>{{ $store.getters.dataTypeusercurrent($store.state.account_active['type'],$t('default')) }}</v-list-item-subtitle>
              </v-list-item-content>
                <v-list-item-avatar>
                <v-menu transition="slide-x-transition" left>
                <template v-slot:activator="{ on }">
                  <v-btn fab text v-on="on" small >
                    <v-icon>mdi-palette</v-icon>
                  </v-btn>
                </template>
                 <v-layout style="background-color:white;" class="pa-1">
                  <v-flex>
                    <v-row no-gutters>
                      <v-subheader class="pa-0 pl-1">{{$t('theme')}}</v-subheader>
                    </v-row>
                    <v-row no-gutters>
                    <v-divider></v-divider>
                      <v-col v-for="(item,n1) in color_1" :key="n1" class="pa-1">
                         <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card>  -->
                         <v-avatar :color="item.name" size="36" @click="changecolor(item.data)" class="elevation-4"  style="cursor: pointer"><v-icon :dark="color.darkTheme" v-if="color.theme === item.name">done</v-icon></v-avatar>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col v-for="(item,n2) in color_2" :key="n2" class="pa-1">
                        <v-avatar :color="item.name" size="36" @click="changecolor(item.data)"  class="elevation-4"  style="cursor: pointer"><v-icon :dark="color.darkTheme" v-if="color.theme === item.name">done</v-icon></v-avatar>
                         <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card> -->
                       </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col v-for="(item,n3) in color_3" :key="n3" class="pa-1">
                        <v-avatar :color="item.name" size="36" @click="changecolor(item.data)" class="elevation-4"  style="cursor: pointer"><v-icon :dark="color.darkTheme" v-if="color.theme === item.name">done</v-icon></v-avatar>
                         <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card> -->
                       </v-col>
                    </v-row>   
                    <!-- <v-col>
                    <v-row>
                      <v-btn v-for="(item, i) in colors" :key="i" v-if="i<5" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                    </v-row><v-row>
                      <v-btn v-for="(item, i) in colors" :key="i" v-if="i>4 && i<10" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                    </v-row><v-row>
                      <v-btn v-for="(item, i) in colors" :key="i" v-if="i>9 && i<15" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                    </v-row><v-row>
                      <v-btn v-for="(item, i) in colors" :key="i" v-if="i>14 && i<20" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                    </v-row>
                    </v-col>-->
                    <v-row><v-subheader class="pa-0 pl-4 pointer" @click="openColorpicker=true">สีเพิ่มเติม...</v-subheader></v-row>
                  </v-flex>
                </v-layout>  
                
              </v-menu>    
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
          
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-layout>
                <v-flex  >
            <v-btn :dark="color.darkTheme" :color="color.theme" text @click="gotoMydrive()" >
              <v-icon small class="pa-1 pt-0 pb-0" >
                mdi-cloud
              </v-icon>
              Mydrive
            </v-btn>
              </v-flex >
              <v-flex class="pa-1 pt-0 pb-0" >
            <v-btn :dark="color.darkTheme" :color="color.theme" outlined @click="openpro()" >
              <v-icon small  class="pa-1 pt-0 pb-0">
                settings
              </v-icon>
              {{$t('Personal')}}
            </v-btn>
              </v-flex >
              <v-flex class="pa-1 pt-0 pb-0" >
            <v-btn color="error" depressed to="/logout" >
            <v-icon 
            small
            class="pa-1 pt-0 pb-0"
            >
            mdi-logout
            </v-icon>
            {{$t('logout')}}</v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-menu>
      <!-- เปลี่ยนภาษา -->
      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab text v-on="on" small>
            <flag :iso="defaultflag" :squared="false" />
          </v-btn>
        </template>
        <v-list nav dense>
          <v-list-item v-for="(item, i) in language" :key="i" @click="changelang(item.data)">
            <v-list-item-action>
              <flag :iso="item.flag" :squared="false" />
            </v-list-item-action>
            <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
          </v-list-item>
        </v-list>
        </v-menu>
        </v-app-bar>
        <dialogprofile
      :show="openprofile"
      @closedialog="openprofile = false"
    ></dialogprofile>
    <colorpicker
      :show="openColorpicker"
      :setColor="setcolor"
      @close="closeDialog(), openColorpicker = false"
      @openColorpicker="openColorpicker = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
    ></colorpicker>
      </div>  
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
const uploadfolder = () => import("../upload/dialog-uploadfolder");
const uploadfiles = () => import("../upload/dialog-uploadfiles2");
const dialogprofile = () => import("../optional/dialog-profile");
const colorpicker = () => import("../optional/dialog-colorpicker");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  props: ["quicksearch","setcolor"],
  components: { dialogprofile,colorpicker},
  data: () => ({
    type:'hex',
    hex: '#FF00FF',
    openprofile: false,
    search: "",
    counter: 0,
    defaultaccount: "ผู้ใช้งานทั่วไป",
    defaultflag: "",
    typeuser: [],
    choosetypeuser: {},
    language: [
      {
        flag: "us",
        name: "languages.english",
        data: "en"
      },
      {
        flag: "th",
        name: "languages.thai",
        data: "th"
      }
    ],
    dialog: false,
    drawer: null,
    tabs: 2,
    openColorpicker:false,
     color_1: [
      {
        name: "#B71C1C", 
        data: '#B71C1C'
      },
      {
        name: "#EF5F33", 
        data: "#EF5F33"
      },
      {
        name: "#FFA000", 
        data: "#FFA000"
      },
      {
        name: "#228B22", 
        data: "#228B22"
      }
    ],
     color_2: [
      {
        name: "#7CB342", 
        data: "#7CB342"
      },
      {
        name: "#000080", 
        data: "#000080"
      },
      {
        name: "#1E90FF", 
        data: "#1E90FF"
      },
      {
        name: "#622D90", 
        data: "#622D90"
      }
    ],
     color_3: [
      {
        name: "#F17B9B", 
        data: "#F17B9B"
      },
      {
        name: "#6D4C41", 
        data: "#6D4C41"
      },
      {
        name: "#696969", 
        data: "#696969"
      },
      {
        name: "#1976D2", 
        data: "#1976D2"
      },
    ],
  }),
  created() {
    this.changelang(localStorage.getItem("lang"));
    this.changecolor(localStorage.getItem("color"));
    this.dataTypeUser();
  },
  computed: {
    ...mapState(["color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters({
      dataBusinessProfile: "dataBusinessProfile",
      dataAccesstoken: "dataAccesstoken"
    }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax"
    ]),

    checkroutername() {
      return this.$router.app["_route"]["name"] || "";
    },
    fullname() {
      if (this.$t("default") === "th") {
        return (
          this.$store.getters.dataCitizenProfile["first_name_th"] +
            " " +
            this.$store.getters.dataCitizenProfile["last_name_th"] || ""
        );
      } else {
        return (
          this.$store.getters.dataCitizenProfile["first_name_th"] +
            " " +
            this.$store.getters.dataCitizenProfile["last_name_th"] || ""
        );
      }
    },
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    sortColor(){
      for(let i = 0 ; i<this.colors.length ; i++){
        return this.colors[i]
      }
    },
    colorset: {
        get () {
          return this[this.type]
        },
        set (v) {
          this[this.type] = v
        },
      },
     showColor () {
        if (typeof this.colorset === 'string') return this.colorset

        return JSON.stringify(Object.keys(this.colorset).reduce((colorset, key) => {
          colorset[key] = Number(this.colorset[key].toFixed(2))
          return colorset
        }, {}), null, 2)
      },
    
  },
  methods: {
    listmenu(){
      let _items = [];
        _items = [
          {
            active: true,
            icon: "dashboard",
            text: "Dash Borad",
            link: "/adminonebox",
            disable: false
          },
           {
            active: true,
            icon: "mdi-server",
            text: "Manage storage",
            link: "/managestorageonebox",
            disable: false
          },]
         

      return _items;
    },
    closeDialog() {
    this.openColorpicker = false;
     
    },
    openpro(){
      this.openprofile = true
    },
    dataTypeUser() {

      let i = 0;
      for (i = 0; i < this.dataBusinessProfile.length; i++) {
         if (this.$store.state.service !== "OneMail"){
            this.typeuser.push({
              type: "Business",
              data: this.dataBusinessProfile[i],
              nameth: this.dataBusinessProfile[i]["first_name_th"],
              nameen: this.dataBusinessProfile[i]["first_name_eng"],
              taxid: this.dataBusinessProfile[i]["id_card_num"]
            });
         }else{
           this.typeuser.push({
              type: "Business",
              data: this.dataBusinessProfile[i],
              nameth: this.dataBusinessProfile[i]["first_name_th"],
              nameen: this.dataBusinessProfile[i]["first_name_eng"],
              taxid: this.dataBusinessProfile[i]["taxid"]
            });
         }
      }

      if (this.dataAccountActive["type"] === "Business") {
        let type_business_index = this.typeuser.findIndex(
          x => x.taxid === this.dataAccountActive["taxid"]
        );
        this.choosetypeuser = this.typeuser[type_business_index];
      } else {
        this.choosetypeuser = {
          type: "Citizen",
          data: [],
          nameth: "ผู้ใช้งานทั่วไป",
          nameen: "Personal"
        };
      }
    },  
    changelang(parameter) {
      localStorage.setItem("lang", parameter);
      this.$i18n.locale = parameter;
      let dflag = this.language.findIndex(v => v.data === parameter);
      this.defaultflag = this.language[dflag]["flag"];
    },
    changecolor(parameter) {
      if(parameter === null){
        localStorage.setItem("color", 'primary');
      }else{
        localStorage.setItem("color", parameter);       
      }
      //console.log("color",parameter);    
      this.$store.dispatch("check_color",localStorage.getItem("color")).then(res => {}); 
  
    },
    gotoMydrive(){
      this.$router.push({path:'/mydrive'});
    },
    searchdata() {
      this.$emit("inputdata", this.search);
    },
    changestorage_sidebar(_choosetypeuser) {
      console.log("_choosetypeuser",_choosetypeuser)
      let beforechoose = this.choosetypeuser;
      if (this.imageHeight <= 500) {
        this.drawer = !this.drawer;
      }
      if (_choosetypeuser.type === "Citizen") {
        this.$store.dispatch("switchaccount_citizen").then(msg => {
          this.$emit("loadfile");
          this.$emit("callstorage");
          this.$router.push({ name: "admin" });
        });
      } else {
        if (this.$store.state.service !== "OneMail") {
          let payload = {
            taxid: _choosetypeuser.data.id_card_num,
            accesstoken: this.dataAccesstoken
          };
          this.$store.dispatch("switchaccount_adminbusiness", payload).then(msg => {
             console.log(msg);

            if (msg["status"] === "Business Access Denied") {
              if (msg["msg"] === "ER401: Authorization is wrong") {
                this.$router.push("logout");
              } else {
                if (this.dataAccountActive["type"] === "Business") {
                  let type_business_index = this.typeuser.findIndex(
                    x => x.taxid === this.dataAccountActive["taxid"]
                  );
                  this.choosetypeuser = this.typeuser[type_business_index];
                } else {
                  this.choosetypeuser = {
                    type: "Citizen",
                    data: [],
                    nameth: "ผู้ใช้งานทั่วไป",
                    nameen: "Personal"
                  };
                }
                Toast.fire({ icon: "error", title: msg["msg"] });
                this.$emit("loadfile");
                this.$emit("callstorage");
                this.$router.push({ name: "admin" });
                // this.$store.dispatch("switchaccount_citizen").then(msg => {
                //   this.$emit("loadfile");
                //   this.$emit("callstorage");
                //   this.$router.push({ name: "myfiles" });
                // });
              }
            } else {
              this.choosetypeuser = _choosetypeuser;
              // console.log(msg);
              this.$emit("loadfile");
              this.$emit("callstorage");
              this.$router.push({ name: "admin" });
            }
          });
        }else{
           this.$store.dispatch("switchaccount_businessonemail", _choosetypeuser).then(msg => {
              this.$emit("loadfile");
              this.$emit("callstorage");
              this.$router.push({ name: "admin" });
          })
        }
      }
    },
  },
  mounted() {}
};
</script>
<style>

</style>